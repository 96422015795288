"use client";

import { forwardRef, useEffect, useState } from "react";
import { getCookie } from "cookies-next";
import { getTenantsByAccountId } from "@/app/dashboard/fetchers/get-tenants-by-account-id";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";

interface Tenant {
  id: string;
  name: string;
}

const TenantSelector = forwardRef<HTMLButtonElement>(() => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [currentTenant, setCurrentTenant] = useState<Tenant>();

  useEffect(() => {
    const accountId = getCookie("accountId");
    const allTenantMode = getCookie("allTenantMode") === "true";

    if (!accountId) {
      console.error("No account ID found");
      return;
    }

    const tenantId = getCookie("tenantId");

    getTenantsByAccountId(accountId)
      .then((fetchedTenants) => {
        setTenants(fetchedTenants);
        if (allTenantMode) {
          setCurrentTenant({ id: "all", name: "All Tenants" });
        } else {
          const current = fetchedTenants.find(t => t.id === tenantId);
          if (current) {
            setCurrentTenant(current);
          }
        }
      })
      .catch(err => console.error("Error fetching tenants:", err));
  }, []);

  return (
    <Select
      value={currentTenant?.id}
      onValueChange={(value) => {
        if (value === "all") {
          document.cookie = `allTenantMode=true;path=/`;
        } else {
          document.cookie = `allTenantMode=false;path=/`;
          document.cookie = `tenantId=${value};path=/`;
        }
        window.location.reload();
      }}
    >
      <SelectTrigger
        className="flex items-center justify-center w-10 h-10 rounded-full transition-colors sidebar-icon-inactive hover:sidebar-icon-active [&>svg]:hidden"
      >
        <span className="text-lg font-bold">
          {currentTenant?.id === "all" ? "*" : currentTenant?.name.charAt(0).toUpperCase() || ""}
        </span>
      </SelectTrigger>
      <SelectContent align="center" className="w-[200px]">
        <SelectItem value="all">All Tenants</SelectItem>
        {tenants.map(tenant => (
          <SelectItem key={tenant.id} value={tenant.id}>
            {tenant.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
});

TenantSelector.displayName = "TenantSelector";

export default TenantSelector;
