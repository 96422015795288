const logger = console;

export type LogLevel = "info" | "error" | "debug" | "warn";

export function logVoice(message: string, logLevel: LogLevel = "info") {
  logger[logLevel](`[VOICE] ${message}`);
}

export function logText(message: string, logLevel: LogLevel = "info") {
  logger[logLevel](`[TEXT] ${message}`);
}

export function logCampaign(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[CAMPAIGN] ${message}`, ...optionalParams);
}

export function logCRM(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[CRM] ${message}`, ...optionalParams);
}

export function logWidget(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[WIDGET] ${message}`, ...optionalParams);
}

export function logFormWidget(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[FORM_WIDGET] ${message}`, ...optionalParams);
}

export function logAudience(message?: any, { level = "info" }: { level?: LogLevel } = {}, ...optionalParams: any) {
  logger[level](`[AUDIENCE] ${message}`, ...optionalParams);
}

export default logger;
