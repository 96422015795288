import { z } from "zod";
const commaSeparatedListSchema = z.string().transform(list => list.split(","));

const envSchema = z.object({
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: z.string().url(),
  NEXT_PUBLIC_VERCEL_ENV: z.string().optional(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: z.string(),
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: z.string(),
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: z.string(),
  NEXT_PUBLIC_BETA_ACCESS_USER_IDS: commaSeparatedListSchema.optional(),
  NEXT_PUBLIC_MULTI_TENANT_ACCOUNT_WHITELIST: commaSeparatedListSchema.optional(),
  NEXT_PUBLIC_DEVELOPER_ACCOUNT_DOMAIN: z.string().optional(),
});

// Must specify full variable names for the NextJS compiler to bundle
const env = envSchema.parse({
  NEXT_PUBLIC_HASURA_GRAPHQL_URL: process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_NETIC_ASSISTANT_ID: process.env.NEXT_PUBLIC_NETIC_ASSISTANT_ID,
  NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_MEDIA_DOMAIN,
  NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME: process.env.NEXT_PUBLIC_SERVICE_TITAN_HOSTNAME,
  NEXT_PUBLIC_BETA_ACCESS_USER_IDS: process.env.NEXT_PUBLIC_BETA_ACCESS_USER_IDS,
  NEXT_PUBLIC_MULTI_TENANT_ACCOUNT_WHITELIST: process.env.NEXT_PUBLIC_MULTI_TENANT_ACCOUNT_WHITELIST,
  NEXT_PUBLIC_DEVELOPER_ACCOUNT_DOMAIN: process.env.NEXT_PUBLIC_DEVELOPER_ACCOUNT_DOMAIN,
});

export default env;
